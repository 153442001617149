import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { findPriceById, useStipeProductData } from "../lib/products";
import { findCorrespondingHardwareId, useCartStore } from "../lib/store";
import {
  Button,
  HeaderPlaceholder,
  UnavailableOverlay,
} from "../styles/ui.styles";
import {
  WantHardware,
  WantHardwareCheckbox,
} from "../styles/configurator.styles";
import products from "../../content/products/products.json";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import CheckoutButton from "../components/cart/checkoutButton";
import CartItem from "../components/cart/cartItem";
import { Prettify } from "../lib/prettify";

import { useSnackbar } from "notistack";

const Cart: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: "success",
      className: "snackbar",
    });
  };

  const handleWantHardware = () => {
    // * if hardware is not available, don't do anything
    if (software) {
      if (
        products.find(
          (node) => node.id === findCorrespondingHardwareId(software.id)
        )?.available === false
      ) {
        setWithHardware(false);
        return;
      }
    }
    // this can only run if there was no hardware added before, otherwise the button is hidden
    setWithHardware(!withHardware);
    // use the software boatType which is the same and already known
    const name = Prettify(
      products.find((node) => node.id === software?.id)?.boatType as BoatType
    );
    showSnackbar(`Added ${name} hardware to the cart`);
  };

  const handleAddAddon = (relatedProduct: { id: string; name?: string }) => {
    addAddon(relatedProduct.id);
    showSnackbar(`Added ${relatedProduct.name} to the cart`);
  };

  const {
    hardware,
    software,
    addons,
    setWithHardware,
    withHardware,
    addAddon,
    cartItemCount,
    update,
  } = useCartStore();
  const stripeProductData = useStipeProductData();

  const [totalPrice, setTotalPrice] = useState<number>(0);
  // mode for checkout session
  const [mode, setMode] = useState<PaymentMode>("subscription");
  // boat type is used to upsell addons for that type
  const [boatType, setBoatType] = useState<BoatType | undefined>(undefined);
  useEffect(() => {
    software &&
      setBoatType(
        products.find((node) => node.id === software.id)?.boatType as BoatType
      );
  }, [software]);

  const updateTotalPrice = () => {
    let totalPrice = 0;
    totalPrice += findPriceById(
      hardware?.id || "",
      stripeProductData
    ).priceInCents;
    totalPrice += findPriceById(
      software?.id || "",
      stripeProductData
    ).priceInCents;
    addons.forEach(
      (addon) =>
        (totalPrice +=
          findPriceById(addon?.id || "", stripeProductData).priceInCents *
          addon.quantity)
    );
    setTotalPrice(totalPrice);
  };

  const updatePaymentMode = () => {
    let mode: PaymentMode = "subscription";
    // checkout mode is subscription, unless cart has 0 subscription items (only pissible for software basic or lifetime)
    if (software) {
      if (
        stripeProductData?.allStripePrice?.edges?.find(
          (node) => node.node.id === software.id
        )?.node.type === "one_time"
      ) {
        mode = "payment";
      } else {
        mode = "subscription";
      }
    } else {
      mode = "payment";
    }
    setMode(mode);
  };

  // update payment mode and total price on cart change
  useEffect(() => {
    updatePaymentMode();
    updateTotalPrice();
  }, [hardware, software, addons]);

  useEffect(() => {
    update();
  }, []);

  return (
    <>
      <SEO title={"Cart"} description={"Cart"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Cart</h1>
          {cartItemCount === 0 && <h2>Your cart is empty</h2>}
          {cartItemCount !== 0 && (
            <CartHeader>
              <div />
              <div />
              <div style={{ width: "100%" }}>
                <p>Name</p>
              </div>
              <div>
                <p>Price</p>
              </div>
              <div>
                <p>Quantity</p>
              </div>
              <div>
                <p>Subtotal</p>
              </div>
            </CartHeader>
          )}
          {hardware && <CartItem productType={"hardware"} product={hardware} />}
          {!hardware && software && (
            <WantHardwareWrapper>
              <WantHardware>
                <WantHardwareCheckbox
                  checked={withHardware}
                  onChange={handleWantHardware}
                  className="custom"
                  id="checkbox"
                  type="checkbox"
                />
                <p>I want to add hardware</p>
              </WantHardware>
            </WantHardwareWrapper>
          )}
          {software && <CartItem productType={"software"} product={software} />}
          {addons &&
            addons.map((addon, idx) => {
              return (
                <CartItem key={idx} productType={"addon"} product={addon} />
              );
            })}
          {cartItemCount !== 0 && (
            <>
              <p>
                If you would like to buy multiple packages, please contuct us at{" "}
                <a
                  href="mailto:privacy@fastrrr.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  sales@fastrrr.com
                </a>
              </p>
            </>
          )}
          {cartItemCount !== 0 && (
            <>
              <SumWrapper>
                <div>
                  <p>Sum</p>
                </div>
                <div>
                  <p>
                    €{totalPrice.toString().slice(0, -2)}.
                    {totalPrice.toString().slice(-2)}
                  </p>
                </div>
              </SumWrapper>
              <CheckoutButton mode={mode} />
            </>
          )}
          {cartItemCount !== 0 && (
            <>
              <h2>Related Products</h2>
              <Grid>
                {products
                  .filter(
                    (node) =>
                      (boatType !== undefined &&
                        node.upsellWith === boatType) ||
                      node.upsellWith === "all"
                  )
                  .map((relatedProduct, idx) => {
                    return (
                      <Card key={idx}>
                        <CardImageWrapper>
                          {stripeProductData?.allStripePrice?.edges?.find(
                            (node) => node.node.id === relatedProduct.id
                          )?.node?.product?.localFiles?.[0]?.childImageSharp
                            ?.gatsbyImageData && (
                            <GatsbyImage
                              style={{ width: "100%", height: "100%" }}
                              objectFit={"contain"}
                              image={
                                getImage(
                                  stripeProductData?.allStripePrice?.edges?.find(
                                    (node) => node.node.id === relatedProduct.id
                                  )?.node?.product?.localFiles?.[0]
                                    ?.childImageSharp?.gatsbyImageData
                                ) as IGatsbyImageData
                              }
                              alt={relatedProduct.name ?? "Product"}
                            />
                          )}
                          {!relatedProduct?.available && <UnavailableOverlay />}
                        </CardImageWrapper>
                        <CardInfoWrapper>
                          <div>
                            <h4>{relatedProduct.name ?? ""}</h4>
                            <p>
                              €
                              {
                                findPriceById(
                                  relatedProduct.id,
                                  stripeProductData
                                ).euros
                              }
                              .
                              {
                                findPriceById(
                                  relatedProduct.id,
                                  stripeProductData
                                ).cents
                              }
                            </p>
                          </div>
                          <Button
                            primary={true}
                            disabled={!relatedProduct?.available}
                            aria-label="Add to cart"
                            onClick={() => handleAddAddon(relatedProduct)}
                          >
                            {relatedProduct?.available
                              ? `ADD TO CART`
                              : `UNAVAILABLE`}
                          </Button>
                        </CardInfoWrapper>
                      </Card>
                    );
                  })}
              </Grid>
            </>
          )}
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Cart;

const Wrapper = styled.div`
  padding: 0 var(--gutter);
  padding-bottom: 100px;
  max-width: 1600px;
  margin: 0 auto;
`;

const WantHardwareWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-self: center;
  padding-left: 100px;
  border-bottom: 1px solid var(--color-light-grey);
`;
const CartHeader = styled.div`
  font-weight: 500;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid var(--color-light-grey);
  display: grid;
  place-items: center;
  column-gap: 20px;
  grid-template-columns: 50px 100px 1fr 100px 100px 100px;
  grid-template-areas: "remove image name price quantity subtotal";

  @media (max-width: 820px) {
    display: none;
  }
`;

const SumWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 100px;
  place-items: center;
  p {
    font-weight: 500;
    width: auto;
  }

  div:first-child {
    width: 100%;
    padding-left: 10px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  place-items: center;
  justify-content: center;

  @media (max-width: 906px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  width: 500px;
  height: 540px;
  border-radius: 20px;
  box-shadow: var(--shadow);

  @media (max-width: 1165px) {
    width: 400px;
    height: 460px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: auto;
  }
`;

const CardImageWrapper = styled.div`
  position: relative;
  padding: 32px;
  height: 380px;
  width: 100%;

  @media (max-width: 1165px) {
    height: 300px;
  }
`;
const CardInfoWrapper = styled.div`
  border-top: 2px solid var(--color-orange);
  padding: 20px;
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    flex: 1;
  }

  h4 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0.5rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    button {
      width: 100%;
    }
  }
`;
