export const Prettify = (
  input: UseType | BoatType | Period | undefined
): string => {
  switch (input) {
    case "leisure":
      return "Leisure";
    case "racing":
      return "Racing";
    case "dinghy":
      return "DinghyEdition";
    case "yacht":
      return "YachtEdition";
    case "coachboat":
      return "CoachBoatPro";
    case "basic":
      return "Basic";
    case "1month":
      return "monthly";
    case "3months":
      return "every 3 months";
    case "6months":
      return "every 6 months";
    case "1year":
      return "yearly";
    case "lifetime":
      return "for life";
    default:
      return "";
  }
};
