import React, { useState, useEffect } from "react";
import { findPriceById, useStipeProductData } from "../../lib/products";
import products from "../../../content/products/products.json";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import { ImCross } from "react-icons/im";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useCartStore } from "../../lib/store";
import { Prettify } from "../../lib/prettify";
interface Props {
  productType: ProductType;
  product: Hardware | Software | Addon;
}

const CartItem: React.FC<Props> = ({ productType, product }) => {
  const {
    hardware,
    software,
    addons,
    cartItemCount,
    setWithHardware,
    withHardware,
    setSoftware,
    addAddon,
    removeAddon,
    incrementAddon,
    decrementAddon,
  } = useCartStore();
  const stripeProductData = useStipeProductData();

  const [useType, setUseType] = useState<string>("");
  const [boatType, setBoatType] = useState<string>("");
  const [period, setPeriod] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [image, setImage] = useState<IGatsbyImageData | null>(null);

  useEffect(() => {
    if (productType === "hardware" || productType === "software") {
      setUseType(
        Prettify(
          products.find((node) => node.id === product.id)?.useType as UseType
        )
      );
      setBoatType(
        Prettify(
          products.find((node) => node.id === product.id)?.boatType as BoatType
        )
      );
    }
    if (productType === "software") {
      setPeriod(
        Prettify(
          products.find((node) => node.id === product.id)?.period as Period
        )
      );
    }

    if (productType === "addon") {
      const nameFromStripe = stripeProductData?.allStripePrice?.edges?.find(
        (node) => node.node.id === product.id
      )?.node?.product?.name;
      nameFromStripe && setName(nameFromStripe);
    }

    // has image
    if (
      stripeProductData?.allStripePrice?.edges?.find(
        (node) => node.node.id === product.id
      )?.node?.product?.localFiles?.[0]?.childImageSharp?.gatsbyImageData
    ) {
      setImage(
        getImage(
          stripeProductData?.allStripePrice?.edges?.find(
            (node) => node.node.id === product.id
          )?.node?.product?.localFiles?.[0]?.childImageSharp?.gatsbyImageData
        ) as IGatsbyImageData
      );
    }
  }, []);

  return (
    <>
      {productType === "hardware" && (
        <ItemWrapper>
          <RemoveButtonWrapper>
            <RemoveButton
              aria-label="Remove from cart"
              onClick={() => setWithHardware(false)}
            >
              <ImCross />
            </RemoveButton>
          </RemoveButtonWrapper>
          <ImageWrapper>
            {image && (
              <GatsbyImage
                style={{ width: "100px", height: "100px" }}
                objectFit={"contain"}
                image={image}
                alt={boatType}
              />
            )}
          </ImageWrapper>
          <ProductName>
            <p>
              {boatType} - {useType}
            </p>
          </ProductName>
          <PriceWrapper>
            <p>
              €{findPriceById(product.id, stripeProductData).euros}.
              {findPriceById(product.id, stripeProductData).cents}
            </p>
          </PriceWrapper>
          <QuantityWrapper>
            <div />
            <p>1</p>
            <div />
          </QuantityWrapper>
          <TitleWrapper>
            <p>Subtotal:</p>
          </TitleWrapper>
          <SubtotalWrapper>
            <p>
              €{findPriceById(product.id, stripeProductData).euros}.
              {findPriceById(product.id, stripeProductData).cents}
            </p>
          </SubtotalWrapper>
        </ItemWrapper>
      )}
      {productType === "software" && (
        <ItemWrapper>
          <RemoveButtonWrapper>
            <RemoveButton
              aria-label="Remove from cart"
              onClick={() => setSoftware(null)}
            >
              <ImCross />
            </RemoveButton>
          </RemoveButtonWrapper>
          <ImageWrapper>
            {image && (
              <GatsbyImage
                style={{ width: "100px", height: "100px" }}
                objectFit={"contain"}
                image={image}
                alt={boatType}
              />
            )}
          </ImageWrapper>
          <ProductName>
            <p>
              {productType} - {useType} - {period}
            </p>
          </ProductName>
          <PriceWrapper>
            <p>
              €{findPriceById(product.id, stripeProductData).euros}.
              {findPriceById(product.id, stripeProductData).cents}
            </p>
          </PriceWrapper>
          <QuantityWrapper>
            <div />
            <p>1</p>
            <div />
          </QuantityWrapper>
          <TitleWrapper>
            <p>Subtotal:</p>
          </TitleWrapper>
          <SubtotalWrapper>
            <p>
              €{findPriceById(product.id, stripeProductData).euros}.
              {findPriceById(product.id, stripeProductData).cents}
            </p>
          </SubtotalWrapper>
        </ItemWrapper>
      )}
      {productType === "addon" && (
        <ItemWrapper>
          <RemoveButtonWrapper>
            <RemoveButton
              aria-label="Remove from cart"
              onClick={() => removeAddon(product.id)}
            >
              <ImCross />
            </RemoveButton>
          </RemoveButtonWrapper>
          <ImageWrapper>
            {image && (
              <GatsbyImage
                style={{ width: "100px", height: "100px" }}
                objectFit={"contain"}
                image={image}
                alt={boatType}
              />
            )}
          </ImageWrapper>
          <ProductName>
            <p>{name}</p>
          </ProductName>
          <PriceWrapper>
            <p>
              €{findPriceById(product.id, stripeProductData).euros}.
              {findPriceById(product.id, stripeProductData).cents}
            </p>
          </PriceWrapper>
          <QuantityWrapper>
            <QuantityButton
              aria-label="decrement item"
              onClick={() => decrementAddon(product.id)}
            >
              <FaMinus />
            </QuantityButton>
            <p>{(product as Addon).quantity}</p>
            <QuantityButton
              aria-label="increment item"
              onClick={() => incrementAddon(product.id)}
            >
              <FaPlus />
            </QuantityButton>
          </QuantityWrapper>
          <TitleWrapper>
            <p>Subtotal:</p>
          </TitleWrapper>
          <SubtotalWrapper>
            <p>
              €
              {(
                findPriceById(product.id, stripeProductData).priceInCents *
                (product as Addon).quantity
              )
                .toString()
                .slice(0, -2)}
              .
              {(
                findPriceById(product.id, stripeProductData).priceInCents *
                (product as Addon).quantity
              )
                .toString()
                .slice(-2)}
            </p>
          </SubtotalWrapper>
        </ItemWrapper>
      )}
    </>
  );
};

export default CartItem;

const ItemWrapper = styled.div`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid var(--color-light-grey);
  display: grid;
  place-items: center;
  column-gap: 20px;
  grid-template-columns: 50px 100px 1fr 100px 100px 100px;
  grid-template-areas: "remove image name price quantity subtotal";

  @media (max-width: 820px) {
    height: 200px;
    column-gap: 0;
    grid-template-columns: 100px 1fr 100px 40px 40px;
    grid-template-rows: 50px 50px 50px 50px;
    grid-template-areas:
      ". . . . remove"
      "image name name name name"
      "image . . quantity quantity"
      "title title title title subtotal";
  }
`;

const RemoveButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 99px;
  background: var(--color-orange);
  transition: 0.2s;
  &:hover {
    background: var(--color-orange-hovered);
  }
  svg {
    height: 13px;
    width: 13px;
    fill: #fff;
  }
`;

const RemoveButtonWrapper = styled.div`
  grid-area: remove;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  grid-area: image;
`;
const ProductName = styled.div`
  grid-area: name;
  justify-self: start;
  p {
    font-weight: 500;
    color: var(--color-orange);
    text-decoration: underline;
  }
  @media (max-width: 820px) {
    align-self: center;
    padding-left: 32px;
  }
`;
const PriceWrapper = styled.div`
  grid-area: price;
  @media (max-width: 820px) {
    display: none;
  }
`;

const QuantityWrapper = styled.div`
  grid-area: quantity;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuantityButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: var(--color-middle-grey);
  transition: 0.2s;
  &:hover {
    background: var(--color-light-grey);
  }
  svg {
    height: 10px;
    width: 10px;
    fill: #fff;
  }
`;

const SubtotalWrapper = styled.div`
  grid-area: subtotal;
`;

const TitleWrapper = styled.div`
  justify-self: flex-end;
  padding-right: 32px;
  display: none;
  grid-area: title;
  @media (max-width: 820px) {
    display: block;
  }
`;
