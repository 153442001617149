import React from "react";
import { useCartStore } from "../../lib/store";
import { Button } from "../../styles/ui.styles";

interface Props {
  mode: PaymentMode;
}

const CheckoutButton: React.FC<Props> = ({ mode }) => {
  const { hardware, software, addons, cartItemCount } = useCartStore();

  return (
    <form
      action={`${process.env.GATSBY_SITE_URL}/api/create-checkout-session.php`}
      method="POST"
    >
      <input type="hidden" name="hardware" value={hardware?.id || ""} />
      <input type="hidden" name="software" value={software?.id || ""} />
      <input type="hidden" name="mode" value={mode || ""} />
      <input type="hidden" name="addons" value={JSON.stringify(addons) || ""} />
      <Button primary={true} disabled={cartItemCount === 0} type="submit">
        CHECKOUT
      </Button>
    </form>
  );
};

export default CheckoutButton;
