import React from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export const ConfiguratorWrapper = styled.section`
  width: 100%;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px var(--gutter);

  button {
    text-decoration: none;
  }

  h3 {
    text-align: center;
  }
`;

export const SoftwareSolutions = styled.section`
  width: 100%;
  background: #f8f8f8;
`;

export const SoftwareSolutionsInner = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  padding: 20px var(--gutter);
  padding-bottom: 100px;
  & > h3 {
    text-align: center;
    margin: 100px 0;
  }
`;

export const SoftwareCard = styled.div<{ selected?: boolean }>`
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 600px;
  border-radius: 32px;
  box-shadow: var(--shadow);
  box-shadow: ${(props) =>
    props.selected
      ? `var(--shadow), 0px 4px 30px rgba(248, 51, 28, 0.4)`
      : `var(--shadow)`};
  border: ${(props) =>
    props.selected ? `2px solid var(--color-orange)` : `2px solid #fff0`};
  padding: 16px;
  padding-bottom: 80px;

  &:after {
    content: "selected";
    display: ${(props) => (props.selected ? `block` : `none`)};
    font-size: 14px;
    line-height: 18px;
    padding: 4px 16px 4px 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    top: 0;
    left: 50%;
    background: var(--color-orange);
    color: #fff;
    transform: translate(-50%, -100%);
  }

  h4 {
    text-align: center;
    width: 100%;
    margin: 20px auto;
    padding-bottom: 20px;
    color: var(--color-orange);
    border-bottom: 1px solid rgba(248, 51, 28, 0.17);
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    text-decoration: none;
  }

  button {
    position: absolute;
    bottom: 25px;
    margin: 0 auto;
    width: calc(100% - 32px);
  }

  transition: 0.2s;
  &:hover {
    border: 2px solid var(--color-orange-hovered);
  }
`;

export const Cards4 = styled.div`
  display: grid;
  justify-content: center;
  place-items: center;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 32px;

  @media (max-width: 1600px) {
    grid-template-columns: 1fr;
    row-gap: 64px;
    ${SoftwareCard} {
      padding: 32px;
      padding-bottom: 100px;
      button {
        width: calc(100% - 64px);
      }
    }
  }
  @media (max-width: 480px) {
    ${SoftwareCard} {
      padding: 16px;
      padding-bottom: 80px;
      button {
        width: calc(100% - 32px);
      }
    }
  }
`;

export const Cards3 = styled.div`
  display: grid;
  justify-content: center;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 32px;

  ${SoftwareCard} {
    padding: 32px;
    padding-bottom: 100px;
    button {
      width: calc(100% - 64px);
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    row-gap: 64px;

    ${SoftwareCard} {
      padding: 32px;
      padding-bottom: 100px;
      button {
        width: calc(100% - 64px);
      }
    }
  }
  @media (max-width: 480px) {
    ${SoftwareCard} {
      padding: 16px;
      padding-bottom: 80px;
      button {
        width: calc(100% - 32px);
      }
    }
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin-right: 32px;
  }
`;

export const StyledListElement = styled.strong`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    fill: var(--color-orange);
    margin-right: 16px;
  }
`;

const StyledListElementCard = styled.p<{ disabled?: boolean }>`
  margin: 8px 0;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.disabled ? `var(--color-light-grey)` : `var(--color-dark-grey)`};
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    fill: ${(props) =>
      props.disabled ? `var(--color-light-grey)` : `var(--color-orange)`};
    margin-right: 16px;
  }
`;

export const CardListElement: React.FC = ({ children }) => {
  return (
    <li>
      <StyledListElementCard>
        <FaCheck />
        {children}
      </StyledListElementCard>
    </li>
  );
};
export const CardListElementDisabled: React.FC = ({ children }) => {
  return (
    <li>
      <StyledListElementCard disabled={true}>
        <ImCross />
        {children}
      </StyledListElementCard>
    </li>
  );
};

export const ConfiguratorFlexSection = styled.section`
  position: relative;
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;

  a {
    text-decoration: none;
  }

  h3 {
    text-align: left;
  }

  div {
    width: 100%;
    height: 100%;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }

    p {
      margin-left: 32px;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

// export const ConfiguratorFlexOverlay = styled.div<{ hidden: boolean }>`
export const ConfiguratorFlexOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.6);
  /* display: ${(props) => (props.hidden ? "block" : "none")}; */
`;

export const WantHardware = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  p {
    flex: 1;
  }
`;

export const WantHardwareCheckbox = styled.input`
  &.custom {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-right: 20px;
    padding: 0;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--color-orange);
    appearance: none;
    outline: none;
    cursor: pointer;
    transition: 0.2s;
    &:checked {
      background-color: var(--color-orange);
    }
    &:hover {
      background-color: var(--color-orange-hovered);
    }
  }
`;
